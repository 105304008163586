import React from "react";

const NotFound = ({ theme }) => {
  return (
    <div style={{ textAlign: "center", padding: "50px", color: theme.text }}>
      <h1>404 - Page Not Found</h1>
      <p>Sorry, the page you are looking for does not exist.</p>
      <a href="/" style={{ color: theme.primary }}>
        Go Back Home
      </a>
    </div>
  );
};

export default NotFound;
